import React from 'react';
import { reduxForm } from 'redux-form';
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { FormGroupList, Input, Dialog, Button, notification, ListSelector } from 'tyb';
import { TablePopConfirm } from 'ucode-components';
const { loadTemplateConfigById, addTemplateConfig, editTemplateConfig, loadFields, loadTagMaterials, addTagMaterial, editTagMaterial } = iceStarkData.store.get('commonAction')?.codeTempConfig
const { setNavBar, updateAddOrEditTagMaterialDialogStatus } = iceStarkData.store.get('commonAction')?.ui;
import { FormGroupField } from 'tyb';
import Upload from 'rc-upload';
import urls from '../../api/urls';
import { NewSelect } from 'ucode-components';
import AddOrEditTagMaterialForm from './components/AddOrEditTagMaterialFrom'
import { AddPool } from 'ucode-components';
import enums from '@/assets/enum.json';

import * as validation from './vailds';
import './CreateOrEdit.scss';

import queryString from 'query-string';
import { apiBaseConfig } from '@/redux/utils';

class UploadFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.uploaderProps = {
      accept: 'application/pdf',
      action: urls.data.upload.upload('PUBLIC'),
      data: { folder: 'app' },
      ...apiBaseConfig,
      // multiple: true,
      beforeUpload: (file) => {
        const isFile = this.uploaderProps.accept.includes(file.type);
        if (!isFile) {
          notification.warning('请确认文件格式')
        }
        const isLt2M = file.size < 5 * 1024 * 1024;
        if (!isLt2M) {
          notification.warning('文件不能大于5M')
        }
        return isFile && isLt2M;
      },
      onStart: (file) => {
      },
      onSuccess: (file, source = {}) => {
        // const { createOrEditForm } = this.props;
        // this.props.initialize({ ...createOrEditForm.values, descriptionFile: file.url });
        // this.props.change('descriptionFile', file.url)
        this.setState({ fileName: source.name });
        this.props.onChange(file.url);
      },
      onProgress(step, file) {
      },
      onError(err) {
      },
    }
  }
  render() {
    return (
      <div style={{ fontSize: '12px', color: '#666' }}>
        <Upload {...this.uploaderProps}>
          <Button className="weak-button">点击文件上传</Button>
        </Upload>
        <div>{this.state.fileName ? this.state.fileName : this.props.value ? this.props.value.split('/')[this.props.value.split('/').length - 1] : ''}</div>
      </div>
    )
  }
}

const setting = (props) => {

  return (
    <Button onClick={props.settingProps.click} className="weak-button" >设置</Button>
  )
}

const required = validation.required('请输入');

const selected = validation.required('请选择');

const maxLength30 = validation.maxLength(30);

const isNumber = validation.isNumber;

const min24 = validation.min(25);
const min27 = validation.min(28);
const min29 = validation.min(30);
const min0 = validation.min(0);
const min1 = validation.min(1);

const max51 = validation.max(50);
const max79 = validation.max(9999999);









class CreateOrEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedList: [],
      editSelectedList: [],
      encryptionTypeList: Object.keys(enums.encryptionType).map(item => { return { value: item, text: enums.encryptionType[item].name } }),

      bindTypeList: [
        {
          text: '按批次',
          value: 'BATCH'
        },
        {
          text: '按卷',
          value: 'ROLL'
        },
      ],
      tagPositionList: [
        {
          text: 'A位置',
          value: 'A'
        },
        {
          text: 'B位置',
          value: 'B'
        },
        {
          text: 'C位置',
          value: 'C'
        },
      ],
      printFileSortDirectionList: [
        {
          text: '横向',
          value: 'TRANSVERSE'
        },
        {
          text: '竖向',
          value: 'VERTICAL'
        },
      ],
      tagLevelList: [
        {
          text: '第一层',
          value: 'FIRST_LAYER',
        },
        {
          text: '第二层',
          value: 'SECOND_LAYER',
        },
        {
          text: '第三层',
          value: 'THIRD_LAYER',
        },
        // {
        //   text: '第四层',
        //   value: 'FOURTH_LAYER',
        // },
      ],
      codeSourceTypeList: [
        {
          text: '优码标准',
          value: 'PLATFORM_STANDARD',
        },
        // 迎驾贡注释
        // {
        //   text: '优码短码',
        //   value: 'PLATFORM_SHORT_CODE',
        // },
        // {
        //   text: '第三方导入码',
        //   value: 'CODE_IMPORT',
        // },
        // {
        //   text: '微信码',
        //   value: 'WECHART_CODE'
        // },
        // {
        //   text: '核销码',
        //   value: 'WRITE_OFF_CODE'
        // }

      ],

    }
  }

  componentDidMount() {
    const { location, setNavBar, loadFields, loadTagMaterials, loadTemplateConfigById } = this.props;
    const parsed = queryString.parse(location.search);
    const { id } = parsed;
    if (id) {
      this.setState({
        id
      })
      setNavBar('编辑印刷配置');
      loadTemplateConfigById(id)
    } else {
      setNavBar('新增印刷配置');
    }
    loadFields();
    loadTagMaterials();


  }


  modalOk = () => {
    this.setState({
      visible: false,
      editSelectedList: [...this.state.selectedList]
    })

    const result = this.state.selectedList.toString().replace(/,/g, "|")
    this.props.change('faceds[0].fieldsConfig', result);
  }

  modalCancel = () => {
    this.setState({
      visible: false,
    }, () => {
      setTimeout(() => {
        this.setState({ selectedList: this.state.editSelectedList });
      }, 200);
    });
  }

  changeList = (value) => {
    this.setState({
      selectedList: value
    })
  }

  submitAddOrEditTagMaterial = (values) => {
    const { editTagMatId, tagMaterialFormType, editTagMatVersion } = this.state;
    if (tagMaterialFormType === 'add') {
      this.props.addTagMaterial(values);
    } else {
      this.props.editTagMaterial({
        ...values,
        id: editTagMatId,
        version: editTagMatVersion
      });
    }
  }

  cancelTagMaterialDialog = () => {
    this.props.updateAddOrEditTagMaterialDialogStatus(false);
  }

  submit = (values) => {
    const { addTemplateConfig, editTemplateConfig, history } = this.props;
    const data = { ...values };
    if (!(data.downloadCount > 0)) {
      delete data.downloadCount;
    }

    if (this.state.id) {
      editTemplateConfig({
        data: { ...data, id: this.state.id },
        history
      });
    } else {
      addTemplateConfig({
        data,
        history
      });
    }

  }

  canCancel = () => {
    this.setState({
      confirmStatus: true
    });
  }

  cancel = () => {
    this.props.history.goBack();
  }


  render() {
    const { permissionIds, createOrEditForm, initialValues, handleSubmit, change, fields, tagMaterials,
      addOrEditTagMaterialDialogStatus, history: { location: { pathname = '' } } } = this.props;
    const { visible, selectedList, codeSourceTypeList, tagLevelList, encryptionTypeList,
      printFileSortDirectionList, tagPositionList, tagMaterialFormType, editTagMatId,
      editTagMatName, bindTypeList, id } = this.state;
    const formValues = createOrEditForm && createOrEditForm.values ? createOrEditForm.values : {}

    return (
      <div className="CreateOrEdit">
        <div className="title">基本信息</div>
        <FormGroupList>
          <FormGroupField
            name="name"
            label="码印刷配置名称"
            component={Input}
            required={true}
            validate={[required, maxLength30]}
          >
            <div className="extraText"> 支持中文、英文字母、中划线和下划线，长度不超过30个字符</div>
          </FormGroupField>
          <FormGroupField
            className="button-after"
            name="tagMatId"
            label="赋码材料"
            component={NewSelect}
            style={{ width: 330 }}
            placeholder="选择材料"
            list={tagMaterials}
            required={true}
            validate={[selected]}
          >
            <a
              href="javascript:;"
              className="button-after-text2"
              onClick={() => {
                this.setState({
                  tagMaterialFormType: 'add'
                })
                this.props.updateAddOrEditTagMaterialDialogStatus(true);
              }}
            >
              新建
            </a>
            {
              formValues.tagMatId &&
              <a
                href="javascript:;"
                className="button-after-text2"
                onClick={() => {
                  const editTagMatId = Number(formValues.tagMatId);
                  const editTagMaterial = tagMaterials.filter(v => v.value === editTagMatId);
                  const editTagMatName = editTagMaterial[0].text;
                  const editTagMatVersion = editTagMaterial[0].version;
                  this.setState({
                    editTagMatId,
                    editTagMatName,
                    editTagMatVersion,
                    tagMaterialFormType: 'edit'
                  })
                  this.props.updateAddOrEditTagMaterialDialogStatus(true);
                }}
              > 编辑 </a>
            }

          </FormGroupField>
          <FormGroupField
            name="printType"
            label="印刷方式"
            component={Input}
            required={true}
            validate={[required, maxLength30]}
          >
            <div className="extraText"> 支持中文、英文字母、中划线和下划线，长度不超过30个字符</div>
          </FormGroupField>
          <FormGroupField
            name="descriptionFile"
            label="赋码说明"
            component={UploadFile}
            validate={[]}
          // uploaderProps={this.state.uploaderProps}
          />
          <div className="title" >码信息</div>
          <FormGroupField
            name="codeSourceType"
            label="码源类型"
            list={codeSourceTypeList}
            component={NewSelect}
            style={{ width: 330 }}
            required={true}
            validate={[selected]}
            onChange={(values) => {
              console.log(values);
              if (values === 'CODE_IMPORT') {
                change('length', '38');
                change('direction', 'TRANSVERSE');
                change('tagCountPerLine', '1');
                change('faceds[0].fieldsConfig', '')
                change('encryption', 'COMMON')
              }
            }}
            disabled
          />

          {
            (formValues.codeSourceType === 'PLATFORM_STANDARD' || formValues.codeSourceType === 'PLATFORM_SHORT_CODE' || formValues.codeSourceType === 'WRITE_OFF_CODE') &&
            <FormGroupField
              name="length"
              label="码长度"
              component={Input}
              required={true}
              validate={formValues.codeSourceType === 'PLATFORM_SHORT_CODE' ? [required, isNumber, min27, max51] : [required, isNumber, min29, max51]}
              disabled
            >
              <div className="extraText">{formValues.codeSourceType === 'PLATFORM_SHORT_CODE' ? '只可填写正整数，HTTP(S)://域名/子域名/加密串，长度最小28位，最大50位，加密串最小12位，加密串越短可生成码量越少' : '只可填写正整数，HTTP(S)://域名/子域名/加密串，长度最小30位，最大50位，加密串最小14位，加密串越短可生成码量越少'}</div>
            </FormGroupField>
          }

          <FormGroupField
            name="faceds[0].level"
            label="所在层级"
            component={NewSelect}
            style={{ width: 330 }}
            list={tagLevelList}
            required={true}
            validate={[selected]}
            disabled
          />
          <FormGroupField
            name="faceds[0].position"
            label="位置编号"
            component={NewSelect}
            style={{ width: 330 }}
            list={tagPositionList}
            required={true}
            validate={[selected]}
            disabled
          />
          <div className="title" >文件信息</div>

          {
            formValues.codeSourceType !== 'CODE_IMPORT' && formValues.codeSourceType !== 'WRITE_OFF_CODE' &&
            <FormGroupField
              name="direction"
              label="打印文件格式"
              list={formValues.bindType == 'ROLL' ? [printFileSortDirectionList[1]] : printFileSortDirectionList}
              component={NewSelect}
              style={{ width: 330 }}
              required={true}
              validate={[selected]}
              disabled
            />
          }


          {
            formValues.codeSourceType !== 'CODE_IMPORT' && formValues.codeSourceType !== 'WRITE_OFF_CODE' &&
            <FormGroupField
              name="tagCountPerLine"
              label="打印行标签个数"
              component={Input}
              addonAfter={'个'}
              required={true}
              validate={[required, isNumber, min1, max79]}
              disabled
            >
              <div className="extraText">只可填写正整数</div>
            </FormGroupField>
          }

          {
            formValues.codeSourceType !== 'CODE_IMPORT' && formValues.codeSourceType !== 'WRITE_OFF_CODE' &&
            <FormGroupField
              name="faceds[0].fieldsConfig"
              label="文件字段"
              component={setting}
              required={true}
              settingProps={
                {
                  click: () => {
                    this.setState({
                      visible: true
                    })
                    if (id) {
                      const selectedValues = formValues.faceds[0].fieldsConfig.split('|');
                      const selectedArray = [];
                      selectedValues.forEach(v => {
                        const item = fields.filter(e => e.id === v)[0];
                        if (item) {
                          selectedArray.push(v);
                        }
                      });
                      this.setState({
                        selectedList: selectedArray,
                        editSelectedList: [...selectedArray]
                      })
                    }
                  }
                }
              }
              validate={[required]}
            />
          }

          {
            formValues.codeSourceType !== 'CODE_IMPORT' &&
            <FormGroupField
              name="encryption"
              label="加密方式"
              component={NewSelect}
              style={{ width: 330 }}
              list={encryptionTypeList}
              required={true}
              validate={[selected]}
              disabled
            />
          }


          {formValues.codeSourceType !== 'WRITE_OFF_CODE' &&
            <FormGroupField
              name="bindType"
              label="登记方式"
              component={NewSelect}
              style={{ width: 330 }}
              list={bindTypeList}
              required={true}
              validate={[selected]}
              onChange={(values) => {
                if (values === 'ROLL' && formValues.direction == 'TRANSVERSE') {
                  change('direction', 'VERTICAL');
                }
              }
              }
              disabled
            />
          }

          {/* {
            formValues.codeSourceType !== 'CODE_IMPORT' && formValues.codeSourceType !== 'WRITE_OFF_CODE' &&
            <FormGroupField
              name="downloadCount"
              label="批次下载次数"
              component={Input}
              addonAfter={'次'}
              validate={[isNumber, max79]}
            >
              <div className="extraText">只可填写正整数，不填默认无下载次数</div>
            </FormGroupField>
          } */}


        </FormGroupList>

        <div className="line"></div>

        <div className="button-block" >
          <Button className="weak-button" onClick={this.canCancel} >取消</Button>
          {/* <Button className="weak-button button-margin" onClick={this.perStep} >上一步</Button>   */}
          {((permissionIds.includes('ws.tempConfig.add') && pathname.includes('create')) ||
            (permissionIds.includes('ws.tempConfig.edit') && pathname.includes('edit'))) && <Button className="button-margin" style={{ marginLeft: 20 }} onClick={handleSubmit(this.submit)} >确定</Button>}
          {(this.state.confirmStatus) && <TablePopConfirm
            strongText={'印刷配置暂未完成，确认离开吗？'}
            text={'离开后编辑内容不做保存，请谨慎操作'}
            styles={{ marginTop: -165 }}   // height: 154,
            button={['确定', '取消']}
            onOk={this.cancel}
            onCancel={() => { this.setState({ confirmStatus: false }) }}
          />}
        </div>

        {
          addOrEditTagMaterialDialogStatus &&
          <AddOrEditTagMaterialForm
            onSubmit={this.submitAddOrEditTagMaterial}
            handleCancel={this.cancelTagMaterialDialog}
            tagMaterialFormType={tagMaterialFormType}
            initialValues={tagMaterialFormType === 'edit' ? { name: editTagMatName } : {}}
          >
          </AddOrEditTagMaterialForm>
        }

        <Dialog
          className="fieldModal"
          title="文件字段设置"
          okText="确定"
          cancelText="取消"
          onOk={() => { this.modalOk() }}
          onCancel={() => { this.modalCancel() }}
          visible={visible}
        >
          <div>
            {/* <ListSelector
            list={fields}
            value={selectedList}
            onChange={this.changeList}
          >

          </ListSelector> */}
            <AddPool
              title={'文件字段设置'}
              list={fields}
              value={selectedList}
              onChange={this.changeList}
            >

            </AddPool>
          </div>

        </Dialog>
      </div>
    )
  }
}

let CreateOrEditForm = reduxForm({
  form: 'CreateOrEditForm', // a unique identifier for this form
  enableReinitialize: true,
})(CreateOrEdit)

CreateOrEditForm = connect(
  state => ({
    initialValues: { ...state.codeTempConfig.createOrEdit, encryption: 'IPC', tagCountPerLine: 1 },
    createOrEditForm: state.form.CreateOrEditForm,
    fields: state.codeTempConfig.fields,
    tagMaterials: state.codeTempConfig.tagMaterials,
    addOrEditTagMaterialDialogStatus: state.ui.addOrEditTagMaterialDialogStatus,
    permissionIds: state.users.permissionIds
  }),
  {
    setNavBar,
    loadFields: loadFields.REQUEST,
    loadTagMaterials: loadTagMaterials.REQUEST,
    addTagMaterial: addTagMaterial.REQUEST,
    editTagMaterial: editTagMaterial.REQUEST,
    addTemplateConfig: addTemplateConfig.REQUEST,
    editTemplateConfig: editTemplateConfig.REQUEST,
    updateAddOrEditTagMaterialDialogStatus,
    loadTemplateConfigById: loadTemplateConfigById.REQUEST,
  }
)(CreateOrEditForm)

CreateOrEditForm = withRouter(CreateOrEditForm);

export default CreateOrEditForm;